const LoadingComponent = () => {
  return (
<>
<div className="base-full-page-loader">


<div className="ball-background">
      <div className="ball ball--1"></div>
      <div className="ball ball--2"></div>
      <div className="ball ball--3"></div>
      <div className="ball ball--4"></div>
      <div className="ball ball--5"></div>
    </div>



<div className="loader__content">
<div className="v-responsive v-img loader__image">
  
<div className="v-responsive__sizer"></div>

<img className="v-img__img v-img__img--contain" src="/images/preloader.png"></img></div>


<div className="loader__logo"></div>
<img className="v-img__img v-img__img--contain loog-whitesss" src="/images/bunny-punk-logo.svg"></img>
</div>


<div className="loader__text">Loading...</div>
</div>
</>
  );
};

export default LoadingComponent;
