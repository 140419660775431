
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useContext, useEffect } from 'react';
import Web3Modal from 'web3modal';
import CONFIG from "../../../src/abi/config.json";
import { GlobalContext } from '../../../src/context/GlobalContext';

const providerOptions = {
    cacheProvider: false,
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            rpc: {
                20: 'https://api.elastos.io/eth'
            },
            chainID: 1
        },
    },
};


const Header = () => {
    const { account, delAccount, updateProvider } = useContext(GlobalContext);

    const onDisconnect = async () => {
        const web3modal = new Web3Modal({
            providerOptions
        });

        const clear = await web3modal.clearCachedProvider();
        window.localStorage.clear();

        delAccount();
        setTimeout(() => {
            window.location.reload();
        }, 1);
    }

    return (
        <>
        <div className="disconnect-row">
                      <a
                            href={`${CONFIG.BLOCKCHAIN_EXPLORER}address/${account}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="address-user">
                            {account.slice(0, 5) + '...' + account.slice(38, 42)}
                        </a>
                        <div className="disconsd-22-s-s" style={{ cursor: 'pointer' }} onClick={() => onDisconnect()}>
                            <div className="typo-buysun">
                <img src="./images/logout.png" class="logout-ss"></img>
                            </div>
                        </div>
                        </div>
        </>
    )
}

export default Header