import { useEffect, useState } from "react";
import CONFIG from './../abi/config.json'
import NFTABI from '../abi/nft.json'
import contractABI from './../abi/abi.json'
import { ethers } from "ethers";
import axios from 'axios'
 
const useFetchNFT = (provider, account, fetchNFTs, setFetchNFTs) => {
    const [nft, setNFT] = useState(null);
    const [performanceFee, setPerformanceFee] = useState(0);
    const [names, setNames] = useState([]);
    const fetchWalletNFTs = async (account) => {
        try {
            // const signer = provider.getSigner()
            // const nftContract = new ethers.Contract(CONFIG.NFT_CONTRACT, NFTABI, signer);
            // const tokenids = await nftContract.walletOfOwner(account);
            // console.log(tokenids);
            // let nft = { ownedNfts: [] };
            // for (let i = 0; i < tokenids.length; i++) {
            //     const id = tokenids[i] / 1;
            //     nft.ownedNfts.push({
            //         id: {
            //             tokenId: id.toString(16)
            //         },
            //         media: [{ gateway: `https://ipfs.pasarprotocol.io/ipfs/QmeNd1mS9Rr9Gs9dXVxFwQ9Le9dCcGjeRwKV6BUSXZe5nY` }],
            //         title: `Bunny #${id}`
            //     })
            // }
            // setNFT(nft)
            // setFetchNFTs(false)

 
        
            async function fetchData() {
                let nftData = [];
                let mediaData = [];
            
                for (let i = 1; i <= 69; i++) {
                    try {
                        const nftResponse = await axios.get(`https://bunn.mypinata.cloud/ipfs/QmRVZRdZxrEqqeMv6UtNL9D5D3i5g4LuHWq4edqFAca628/${i}.json`);
                        const nftInfo = nftResponse.data;
                        nftData.push({
                            tokenId: nftInfo.tokenId,
                            name: nftInfo.name
                        });
            
                        // Assuming the media URL is part of the JSON data fetched above
                        if (nftInfo.image) {
                            const mediaUrl = nftInfo.image.replace("ipfs://", "https://bunn.mypinata.cloud/ipfs/");
                            mediaData.push({
                                tokenId: nftInfo.tokenId,
                                mediaUrl
                            });
                        }
                    } catch (error) {
                        console.error(`Failed to fetch data for token ${i}:`, error);
                    }
                }
            
                // Process nftData and mediaData as needed
                console.log(nftData, mediaData);
            }
            
            // Call fetchData function
            fetchData();
            
            console.log("COUNT", nft);
            setNFT(nft);
            setFetchNFTs(false);

        } catch (e) {
            console.log(e)
            setFetchNFTs(false)
        }
    }

    useEffect(() => {
        if (fetchNFTs) {
            fetchWalletNFTs(account)
        }
    }, [account, fetchNFTs])

    return { nft, performanceFee, names }

}

export default useFetchNFT