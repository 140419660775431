import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import { GlobalContext } from '../../context/GlobalContext';
import CONFIG from './../../abi/config.json';
import contractAbi from './../../abi/abi.json';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useContext, useEffect } from 'react';


const providerOptions = {
  cacheProvider: false,
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        20: 'https://api.elastos.io/eth'
      },
      chainID: 1
    },
  },
};

const styleobj = {
  WebkitTransform: "translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)",
  msTransform: "translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)",
  transform: "translate3d(0, 100px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(3deg) skew(0, 0)",
  opacity: 0
}

const Main = ({ setError, setErrMsg }) => {
  const { account, addAccount, updateProvider } = useContext(GlobalContext);

  const connectWallet = async () => {
    try {
      const web3modal = new Web3Modal({
        providerOptions
      });
      const instance = await web3modal.connect();
      const provider = new ethers.providers.Web3Provider(instance);
      updateProvider(provider)
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      addAccount({ id: address });
      const network = await provider.getNetwork();
      if (network.chainId !== CONFIG.NETWORK_ID) {
        setError(true)
        setErrMsg(`Contract is not deployed on current network. please choose ${CONFIG.NETWORK}`)
      } else {
        setError(false)
        setErrMsg('')
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', accounts => {
        // addAccount({ id: accounts[0] })
        connectWallet()
      })
      window.ethereum.on('chainChanged', chainId => {
        window.location.reload();
      })
    }
  }, [account]);


  return (
    <section className="stake-and-earn wf-section">
<div className="background-main-bunnypunk">
  <div className="phishingwarning">
    <div className="div-block-224">
      <div className="div-block-225">
        <div className="div-block-226">
          <div className="html-embed-13 w-embed">
            <div className="ant-col"><span role="img" aria-label="info-circle" className="anticon anticon-info-circle c-icon"><svg viewbox="64 64 896 896" focusable="false" data-icon="info-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                  <path d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                </svg></span></div>
          </div>
        </div>
        <p className="paragraph-22"><strong className="bold-text-16">PHISHING WARNING:</strong> Check carefully and make sure you&#x27;re visiting https://bunnypunk.io - bookmark the URL.</p>
      </div>
    </div>
  </div>
  <div data-animation="over-left" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar-5 w-nav">
    <div className="container-12 w-container">
      <nav role="navigation" className="nav-menu-4 w-nav-menu">
        <div className="p-navbar show-mobile">
          <div className="header-logo"><img src="images/bunnypunk_logo4x_1bunnypunk_logo4x.png" loading="lazy" srcset="images/bunnypunk_logo4x_1-p-500.png 500w, images/bunnypunk_logo4x_1-p-800.png 800w, images/bunnypunk_logo4x_1-p-3200.png 3200w, images/bunnypunk_logo4x_1bunnypunk_logo4x.png 8843w" sizes="100vw" alt="" className="image-122"></img>
            <a href="#" className="button-19 w-button">X</a>
          </div>
          <div className="main-links">
            <ul role="list" className="list-12">
              <li className="list-item-16">
                <a href="https://bunnypunk.io/" aria-current="page" className="link-block-15 soundbtn w-inline-block">
                  <div className="html-embed-15 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity=".4" d="M16.076 2h3.386A2.549 2.549 0 0 1 22 4.56v3.415a2.549 2.549 0 0 1-2.538 2.56h-3.386a2.549 2.549 0 0 1-2.539-2.56V4.56A2.549 2.549 0 0 1 16.076 2" fill="#000"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.539 2h3.385a2.549 2.549 0 0 1 2.539 2.56v3.415a2.549 2.549 0 0 1-2.539 2.56H4.54A2.549 2.549 0 0 1 2 7.974V4.56A2.549 2.549 0 0 1 4.539 2Zm0 11.466h3.385a2.549 2.549 0 0 1 2.539 2.56v3.414A2.55 2.55 0 0 1 7.924 22H4.54A2.55 2.55 0 0 1 2 19.44v-3.415a2.549 2.549 0 0 1 2.539-2.56Zm14.923 0h-3.386a2.549 2.549 0 0 0-2.539 2.56v3.414A2.55 2.55 0 0 0 16.076 22h3.386A2.55 2.55 0 0 0 22 19.44v-3.415a2.549 2.549 0 0 0-2.538-2.56Z" fill="#4340fc"></path>
                    </svg></div>
                  <p className="paragraph-25">Dashboard </p>
                </a>
              </li>
              <li>
                <a href="#" className="link-block-15 soundbtn w-inline-block">
                  <div className="html-embed-15 w-embed">
                  <object data="https://bunn.mypinata.cloud/ipfs/QmRcq8MAKZz9n9hnbeUSN4DSjKriDKkPeZQBQWkT6S4hLJ" type="image/svg+xml">
<img src="https://bunn.mypinata.cloud/ipfs/QmRcq8MAKZz9n9hnbeUSN4DSjKriDKkPeZQBQWkT6S4hLJ"></img></object>
                   
                   </div>
                  <p className="paragraph-24">Roadmap <span class="text-span-20 _2">soon</span></p>
                </a>
              </li>
              <li className="list-item-20">
                <a href="https://bunnypunk.io/stake-pools" className="link-block-15 soundbtn w-inline-block w--current">
                  <div className="html-embed-15 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.243 3.738a4.024 4.024 0 0 0 4.027 4.021c.245-.001.49-.025.73-.07v7.973C20 19.015 18.021 21 14.662 21H7.346C3.98 21 2 19.016 2 15.662V8.355C2 5.002 3.979 3 7.346 3h7.967c-.047.243-.07.49-.07.738ZM13.15 13.897l2.858-3.688v-.018a.754.754 0 0 0-.14-1.045.73.73 0 0 0-1.039.15l-2.409 3.1-2.743-2.16a.74.74 0 0 0-1.047.14l-2.954 3.81a.72.72 0 0 0-.159.457.738.738 0 0 0 1.363.43l2.471-3.196 2.744 2.151a.74.74 0 0 0 1.055-.131Z" fill="#4340fc"></path>
                      <circle opacity=".4" cx="19.5" cy="3.5" r="2.5" fill="#000"></circle>
                    </svg></div>
                  <p className="paragraph-26">Staking</p>
                </a>
              </li>
              <li className="list-item-22">
                <a href="#" className="link-block-15 soundbtn w-inline-block">
                  <div className="html-embed-15 w-embed">
                 
                  <object data="https://bunn.mypinata.cloud/ipfs/QmTML2oYPheqQRffSPABt4MMEeL1N2tJECRcpDP9RT2vDZ" type="image/svg+xml">
<img src="https://bunn.mypinata.cloud/ipfs/QmTML2oYPheqQRffSPABt4MMEeL1N2tJECRcpDP9RT2vDZ"></img></object>
                 </div>
                  <p className="paragraph-24">Farm </p>
                </a>
              </li>
              <li className="list-item-24">
                <a href="#" className="link-block-15 w-inline-block">
                  <div className="html-embed-15 w-embed">
                  <object data="https://bunn.mypinata.cloud/ipfs/QmVWx5q1fBnUP4dQmSZF1TpXvm9Gm3ds52c9rTimXf8SeQ" type="image/svg+xml">
<img src="https://bunn.mypinata.cloud/ipfs/QmVWx5q1fBnUP4dQmSZF1TpXvm9Gm3ds52c9rTimXf8SeQ"></img></object>
 
                 </div>
                  <p className="paragraph-24 paragraph-23">Launchpad <span className="text-span-20 _2">soon</span> </p>
                </a>
              </li>
              <li className="list-item-21">
                <a href="#" className="link-block-15 bridge-comingsoon w-inline-block">
                  <div className="html-embed-15 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 4a2.035 2.035 0 0 0-.636.103l-3.508 1.23a.667.667 0 0 1-.373.008L4.067 4.075a1.535 1.535 0 0 0-.791-.038C2.57 4.19 2 4.824 2 5.6v10.662c0 .715.48 1.35 1.17 1.541l-.07-.024 3.942 1.573a2.036 2.036 0 0 0 1.246-.005l3.51-1.223a.673.673 0 0 1 .404 0l3.51 1.222a2.035 2.035 0 0 0 1.244.006l3.942-1.548-.067.022c.69-.19 1.17-.827 1.169-1.543V5.6c0-1.033-1.013-1.816-2.01-1.541a.686.686 0 0 0-.056.017l-3.416 1.266a.67.67 0 0 1-.374-.008l-3.507-1.23A2.04 2.04 0 0 0 12 4Zm0 1.365c.069 0 .137.01.203.03l3.508 1.231a2.032 2.032 0 0 0 1.231.012l3.412-1.264c.156-.04.282.051.282.225v10.684c0 .112-.068.2-.168.227a.677.677 0 0 0-.069.023l-3.883 1.527a.668.668 0 0 1-.373-.008l-3.508-1.222a2.039 2.039 0 0 0-1.27 0l-3.508 1.222a.667.667 0 0 1-.37.008l-3.883-1.548a.683.683 0 0 0-.07-.024.23.23 0 0 1-.17-.227V5.599c0-.174.126-.266.283-.225l3.41 1.264a2.035 2.035 0 0 0 1.232-.012l3.507-1.23h.001A.674.674 0 0 1 12 5.365ZM6.546 8.998a.455.455 0 1 0 0 .909.455.455 0 0 0 0-.91Zm1.818 0a.455.455 0 1 0 0 .909.455.455 0 0 0 0-.91Zm1.364.909a.455.455 0 1 0 0 .909.455.455 0 0 0 0-.91Zm-4.091.454a.454.454 0 1 0 0 .909.454.454 0 0 0 0-.909Zm12.948.445a.681.681 0 0 0-.475.21L17 12.124l-1.108-1.108a.68.68 0 1 0-.964.964l1.108 1.109-1.108 1.108a.683.683 0 0 0 .479 1.174.681.681 0 0 0 .485-.21L17 14.054l1.109 1.109a.68.68 0 0 0 1.173-.48.681.681 0 0 0-.21-.485l-1.108-1.108 1.109-1.11a.682.682 0 0 0-.489-1.173Zm-7.948.464a.455.455 0 1 0 0 .91.455.455 0 0 0 0-.91Zm-5.455.91a.454.454 0 1 0 0 .908.454.454 0 0 0 0-.908Zm6.818 0a.455.455 0 1 0 0 .909.455.455 0 0 0 0-.91Zm1.819.454a.455.455 0 1 0 0 .91.455.455 0 0 0 0-.91Z" fill="#757b8c"></path>
                    </svg></div>
                  <p className="paragraph-23">Bridge <span className="text-span-20 _2">soon</span> </p>
                </a>
              </li>
              <li className="list-item-23">
                <a href="#" className="link-block-15 w-inline-block">
                  <div className="html-embed-15 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.5 2C3.13 2 2 3.13 2 4.5v4.444c0 1.371 1.13 2.5 2.5 2.5h4.444c1.371 0 2.5-1.129 2.5-2.5V4.5c0-1.37-1.129-2.5-2.5-2.5H4.5Zm10.556 0c-1.371 0-2.5 1.13-2.5 2.5v4.444c0 1.371 1.129 2.5 2.5 2.5H19.5c1.37 0 2.5-1.129 2.5-2.5V4.5C22 3.13 20.87 2 19.5 2h-4.444ZM4.5 3.667h4.444a.82.82 0 0 1 .834.833v4.444a.82.82 0 0 1-.834.834H4.5a.82.82 0 0 1-.833-.834V4.5a.82.82 0 0 1 .833-.833Zm10.556 0H19.5a.82.82 0 0 1 .833.833v4.444a.82.82 0 0 1-.833.834h-4.444a.82.82 0 0 1-.834-.834V4.5a.82.82 0 0 1 .834-.833ZM6.722 5.333a1.389 1.389 0 1 0 0 2.778 1.389 1.389 0 0 0 0-2.778ZM4.5 12.556c-1.37 0-2.5 1.129-2.5 2.5V19.5C2 20.87 3.13 22 4.5 22h4.444c1.371 0 2.5-1.13 2.5-2.5v-4.444c0-1.371-1.129-2.5-2.5-2.5H4.5Zm10.556 0c-1.371 0-2.5 1.129-2.5 2.5V19.5c0 1.37 1.129 2.5 2.5 2.5H19.5c1.37 0 2.5-1.13 2.5-2.5v-4.444c0-1.371-1.13-2.5-2.5-2.5h-4.444ZM4.5 14.222h4.444a.82.82 0 0 1 .834.834V19.5a.82.82 0 0 1-.834.833H4.5a.82.82 0 0 1-.833-.833v-4.444a.82.82 0 0 1 .833-.834Zm10.556 0H19.5a.82.82 0 0 1 .833.834V19.5a.82.82 0 0 1-.833.833h-4.444a.82.82 0 0 1-.834-.833v-4.444a.82.82 0 0 1 .834-.834Zm2.222 1.43a.552.552 0 0 0-.392.163l-1.07 1.071a.553.553 0 0 0 0 .784l1.07 1.07a.553.553 0 0 0 .784 0l1.07-1.07a.553.553 0 0 0 0-.784l-1.07-1.07a.552.552 0 0 0-.392-.164Zm-10.556.514a.31.31 0 0 0-.265.146L5.285 18.19c-.13.208.02.478.264.478h2.346c.245 0 .396-.27.265-.478l-1.173-1.877a.31.31 0 0 0-.265-.146Z" fill="#757b8c"></path>
                    </svg></div>
                  <p className="paragraph-24 paragraph-23">Adventure <span className="text-span-20 _2">soon</span> </p>
                </a>
              </li>
              <li>
            <a id="button" href="https://rarity.bunnypunk.io/" className="link-block-15 soundbtn w-inline-block">
              <div className="html-embed-15 w-embed">
              <object data="https://bunn.mypinata.cloud/ipfs/QmUwonWHmUWRTi2M7omN1vCJFAKT2xDmwYDmTgMrCQkfAY" type="image/svg+xml">
<img src="https://bunn.mypinata.cloud/ipfs/QmUwonWHmUWRTi2M7omN1vCJFAKT2xDmwYDmTgMrCQkfAY"></img></object>

               </div>
              <p className="paragraph-24">Rarity </p>
            </a>
          </li>
              <li>
                <a href="https://bunnypunk.io/mint-pools" className="link-block-15 soundbtn w-inline-block">
                  <div className="html-embed-15 w-embed">
                  <object data="https://bunn.mypinata.cloud/ipfs/QmQ8i57dtp4fFDpn1Am3T9j4mUVoGKjhVc8N5k9aYrA6rv" type="image/svg+xml">
<img src="https://bunn.mypinata.cloud/ipfs/QmQ8i57dtp4fFDpn1Am3T9j4mUVoGKjhVc8N5k9aYrA6rv"></img></object>

                    </div>
                  <p className="paragraph-24">Mint </p>
                </a>
              </li>
            </ul>
          </div>
          <div className="div-block-253">
            <div className="div-block-254">
              <div><img src="images/bunnypunk_token4x_1bunnypunk_token4x.png" loading="lazy" srcset="images/bunnypunk_token4x_1-p-500.png 500w, images/bunnypunk_token4x_1-p-3200.png 3200w, images/bunnypunk_token4x_1bunnypunk_token4x.png 7815w" sizes="100vw" alt="" className="image-122"></img></div>
              <div>
                <p className="paragraph-24 account-address">Welcome!</p>
              </div>
              <div>
                <div className="html-embed-15 w-embed">
                 
                 </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <a href="https://bunnypunk.io/" aria-current="page" className="brand-4 soundbtn w-nav-brand w--current"><img src="images/bunnypunk_white.svg" loading="lazy" alt="" className="image-124"></img></a>
      <div className="menu-button-4 soundbtn w-nav-button">
        <div className="w-icon-nav-menu"></div>
      </div>
    </div>
  </div>
  <div className="div-block-263">
    <div className="ball-background">
      <div className="ball ball--1"></div>
      <div className="ball ball--2"></div>
      <div className="ball ball--3"></div>
      <div className="ball ball--4"></div>
      <div className="ball ball--5"></div>
      <div className="v-responsive v-img ball ball--blue">
        <div className="v-responsive__sizer"><img src="images/blue.750222cf.png" loading="lazy" alt="" className="image-123"></img></div>
      </div>
      <div className="v-responsive v-img ball ball--green">
        <div className="v-responsive__sizer"><img src="images/green.c94ed6f1.png" loading="lazy" alt="" className="image-123"></img></div>
      </div>
      <div className="v-responsive v-img ball ball--purple">
        <div className="v-responsive__sizer"><img src="images/purple.5330ba81.png" loading="lazy" alt="" className="image-123"></img></div>
      </div>
    </div>
    <div className="p-navbar hide-mobile">
      <a href="https://bunnypunk.io/" aria-current="page" className="header-logo soundbtn w-inline-block w--current"><img src="images/bunnypunk_logo4x_1bunnypunk_logo4x.png" loading="lazy" srcset="images/bunnypunk_logo4x_1-p-500.png 500w, images/bunnypunk_logo4x_1-p-800.png 800w, images/bunnypunk_logo4x_1-p-3200.png 3200w, images/bunnypunk_logo4x_1bunnypunk_logo4x.png 8843w" sizes="(max-width: 767px) 100vw, 45px" alt="" className="image-122 soundbtn"></img></a>
      <div className="main-links">
        <ul role="list" className="list-12">
          <li className="list-item-16">
            <a id="button" href="https://bunnypunk.io/" aria-current="page" className="link-block-15 soundbtn w-inline-block">
              <div className="html-embed-15 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity=".4" d="M16.076 2h3.386A2.549 2.549 0 0 1 22 4.56v3.415a2.549 2.549 0 0 1-2.538 2.56h-3.386a2.549 2.549 0 0 1-2.539-2.56V4.56A2.549 2.549 0 0 1 16.076 2" fill="#000"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.539 2h3.385a2.549 2.549 0 0 1 2.539 2.56v3.415a2.549 2.549 0 0 1-2.539 2.56H4.54A2.549 2.549 0 0 1 2 7.974V4.56A2.549 2.549 0 0 1 4.539 2Zm0 11.466h3.385a2.549 2.549 0 0 1 2.539 2.56v3.414A2.55 2.55 0 0 1 7.924 22H4.54A2.55 2.55 0 0 1 2 19.44v-3.415a2.549 2.549 0 0 1 2.539-2.56Zm14.923 0h-3.386a2.549 2.549 0 0 0-2.539 2.56v3.414A2.55 2.55 0 0 0 16.076 22h3.386A2.55 2.55 0 0 0 22 19.44v-3.415a2.549 2.549 0 0 0-2.538-2.56Z" fill="#4340fc"></path>
                </svg></div>
              <p className="paragraph-25">Dashboard </p>
            </a>
          </li>





          <li>
            <a id="button" href="#" className="link-block-15 soundbtn w-inline-block">
              <div className="html-embed-15 w-embed">
              <object data="https://bunn.mypinata.cloud/ipfs/QmRcq8MAKZz9n9hnbeUSN4DSjKriDKkPeZQBQWkT6S4hLJ" type="image/svg+xml">
<img src="https://bunn.mypinata.cloud/ipfs/QmRcq8MAKZz9n9hnbeUSN4DSjKriDKkPeZQBQWkT6S4hLJ"></img></object>
                </div>
              <p className="paragraph-24 paragraph-23">Roadmap <span class="text-span-20 _2">soon</span></p>
            </a>
          </li>
          <li>
            <a id="button" href="https://bunnypunk.io/stake-pools" className="link-block-15 soundbtn w-inline-block w--current">
              <div className="html-embed-15 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.243 3.738a4.024 4.024 0 0 0 4.027 4.021c.245-.001.49-.025.73-.07v7.973C20 19.015 18.021 21 14.662 21H7.346C3.98 21 2 19.016 2 15.662V8.355C2 5.002 3.979 3 7.346 3h7.967c-.047.243-.07.49-.07.738ZM13.15 13.897l2.858-3.688v-.018a.754.754 0 0 0-.14-1.045.73.73 0 0 0-1.039.15l-2.409 3.1-2.743-2.16a.74.74 0 0 0-1.047.14l-2.954 3.81a.72.72 0 0 0-.159.457.738.738 0 0 0 1.363.43l2.471-3.196 2.744 2.151a.74.74 0 0 0 1.055-.131Z" fill="#4340fc"></path>
                  <circle opacity=".4" cx="19.5" cy="3.5" r="2.5" fill="#000"></circle>
                </svg></div>
              <p className="paragraph-26">Staking</p>
            </a>
          </li>
          <li>
            <a id="button" href="https://farm.bunnypunk.io" target="_blank" className="link-block-15 soundbtn w-inline-block">
              <div className="html-embed-15 w-embed">
              <object data="https://bunn.mypinata.cloud/ipfs/QmTML2oYPheqQRffSPABt4MMEeL1N2tJECRcpDP9RT2vDZ" type="image/svg+xml">
<img src="https://bunn.mypinata.cloud/ipfs/QmTML2oYPheqQRffSPABt4MMEeL1N2tJECRcpDP9RT2vDZ"></img></object>
                </div>
              <p className="paragraph-24">Farm </p>
            </a>
          </li>
          <li>
            <a href="#" className="link-block-15 w-inline-block">
              <div className="html-embed-15 w-embed">
<object data="https://bunn.mypinata.cloud/ipfs/QmVWx5q1fBnUP4dQmSZF1TpXvm9Gm3ds52c9rTimXf8SeQ" type="image/svg+xml">
<img src="https://bunn.mypinata.cloud/ipfs/QmVWx5q1fBnUP4dQmSZF1TpXvm9Gm3ds52c9rTimXf8SeQ"></img></object>
                </div>
              <p className="paragraph-24 paragraph-23">Launchpad <span className="text-span-20 _2">soon</span> </p>
            </a>
          </li>
          <li className="list-item-23">
            <a href="#" className="link-block-15 w-inline-block">
              <div className="html-embed-15 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.5 2C3.13 2 2 3.13 2 4.5v4.444c0 1.371 1.13 2.5 2.5 2.5h4.444c1.371 0 2.5-1.129 2.5-2.5V4.5c0-1.37-1.129-2.5-2.5-2.5H4.5Zm10.556 0c-1.371 0-2.5 1.13-2.5 2.5v4.444c0 1.371 1.129 2.5 2.5 2.5H19.5c1.37 0 2.5-1.129 2.5-2.5V4.5C22 3.13 20.87 2 19.5 2h-4.444ZM4.5 3.667h4.444a.82.82 0 0 1 .834.833v4.444a.82.82 0 0 1-.834.834H4.5a.82.82 0 0 1-.833-.834V4.5a.82.82 0 0 1 .833-.833Zm10.556 0H19.5a.82.82 0 0 1 .833.833v4.444a.82.82 0 0 1-.833.834h-4.444a.82.82 0 0 1-.834-.834V4.5a.82.82 0 0 1 .834-.833ZM6.722 5.333a1.389 1.389 0 1 0 0 2.778 1.389 1.389 0 0 0 0-2.778ZM4.5 12.556c-1.37 0-2.5 1.129-2.5 2.5V19.5C2 20.87 3.13 22 4.5 22h4.444c1.371 0 2.5-1.13 2.5-2.5v-4.444c0-1.371-1.129-2.5-2.5-2.5H4.5Zm10.556 0c-1.371 0-2.5 1.129-2.5 2.5V19.5c0 1.37 1.129 2.5 2.5 2.5H19.5c1.37 0 2.5-1.13 2.5-2.5v-4.444c0-1.371-1.13-2.5-2.5-2.5h-4.444ZM4.5 14.222h4.444a.82.82 0 0 1 .834.834V19.5a.82.82 0 0 1-.834.833H4.5a.82.82 0 0 1-.833-.833v-4.444a.82.82 0 0 1 .833-.834Zm10.556 0H19.5a.82.82 0 0 1 .833.834V19.5a.82.82 0 0 1-.833.833h-4.444a.82.82 0 0 1-.834-.833v-4.444a.82.82 0 0 1 .834-.834Zm2.222 1.43a.552.552 0 0 0-.392.163l-1.07 1.071a.553.553 0 0 0 0 .784l1.07 1.07a.553.553 0 0 0 .784 0l1.07-1.07a.553.553 0 0 0 0-.784l-1.07-1.07a.552.552 0 0 0-.392-.164Zm-10.556.514a.31.31 0 0 0-.265.146L5.285 18.19c-.13.208.02.478.264.478h2.346c.245 0 .396-.27.265-.478l-1.173-1.877a.31.31 0 0 0-.265-.146Z" fill="#757b8c"></path>
                </svg></div>
              <p className="paragraph-24 paragraph-23">Adventure <span className="text-span-20 _2">soon</span> </p>
            </a>
          </li>
          <li>
            <a href="#" className="link-block-15 bridge-comingsoon w-inline-block">
              <div className="html-embed-15 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 4a2.035 2.035 0 0 0-.636.103l-3.508 1.23a.667.667 0 0 1-.373.008L4.067 4.075a1.535 1.535 0 0 0-.791-.038C2.57 4.19 2 4.824 2 5.6v10.662c0 .715.48 1.35 1.17 1.541l-.07-.024 3.942 1.573a2.036 2.036 0 0 0 1.246-.005l3.51-1.223a.673.673 0 0 1 .404 0l3.51 1.222a2.035 2.035 0 0 0 1.244.006l3.942-1.548-.067.022c.69-.19 1.17-.827 1.169-1.543V5.6c0-1.033-1.013-1.816-2.01-1.541a.686.686 0 0 0-.056.017l-3.416 1.266a.67.67 0 0 1-.374-.008l-3.507-1.23A2.04 2.04 0 0 0 12 4Zm0 1.365c.069 0 .137.01.203.03l3.508 1.231a2.032 2.032 0 0 0 1.231.012l3.412-1.264c.156-.04.282.051.282.225v10.684c0 .112-.068.2-.168.227a.677.677 0 0 0-.069.023l-3.883 1.527a.668.668 0 0 1-.373-.008l-3.508-1.222a2.039 2.039 0 0 0-1.27 0l-3.508 1.222a.667.667 0 0 1-.37.008l-3.883-1.548a.683.683 0 0 0-.07-.024.23.23 0 0 1-.17-.227V5.599c0-.174.126-.266.283-.225l3.41 1.264a2.035 2.035 0 0 0 1.232-.012l3.507-1.23h.001A.674.674 0 0 1 12 5.365ZM6.546 8.998a.455.455 0 1 0 0 .909.455.455 0 0 0 0-.91Zm1.818 0a.455.455 0 1 0 0 .909.455.455 0 0 0 0-.91Zm1.364.909a.455.455 0 1 0 0 .909.455.455 0 0 0 0-.91Zm-4.091.454a.454.454 0 1 0 0 .909.454.454 0 0 0 0-.909Zm12.948.445a.681.681 0 0 0-.475.21L17 12.124l-1.108-1.108a.68.68 0 1 0-.964.964l1.108 1.109-1.108 1.108a.683.683 0 0 0 .479 1.174.681.681 0 0 0 .485-.21L17 14.054l1.109 1.109a.68.68 0 0 0 1.173-.48.681.681 0 0 0-.21-.485l-1.108-1.108 1.109-1.11a.682.682 0 0 0-.489-1.173Zm-7.948.464a.455.455 0 1 0 0 .91.455.455 0 0 0 0-.91Zm-5.455.91a.454.454 0 1 0 0 .908.454.454 0 0 0 0-.908Zm6.818 0a.455.455 0 1 0 0 .909.455.455 0 0 0 0-.91Zm1.819.454a.455.455 0 1 0 0 .91.455.455 0 0 0 0-.91Z" fill="#757b8c"></path>
                </svg></div>
              <p className="paragraph-23">Bridge <span className="text-span-20 _2">soon</span> </p>
            </a>
          </li>
          <li>
            <a id="button" href="https://rarity.bunnypunk.io/" className="link-block-15 soundbtn w-inline-block">
              <div className="html-embed-15 w-embed">
              <object data="https://bunn.mypinata.cloud/ipfs/QmUwonWHmUWRTi2M7omN1vCJFAKT2xDmwYDmTgMrCQkfAY" type="image/svg+xml">
<img src="https://bunn.mypinata.cloud/ipfs/QmUwonWHmUWRTi2M7omN1vCJFAKT2xDmwYDmTgMrCQkfAY"></img></object>

               </div>
              <p className="paragraph-24">Rarity </p>
            </a>
          </li>
          <li>
            <a id="button" href="https://bunnypunk.io/mint-pools" className="link-block-15 soundbtn w-inline-block">
              <div className="html-embed-15 w-embed">
              <object data="https://bunn.mypinata.cloud/ipfs/QmQ8i57dtp4fFDpn1Am3T9j4mUVoGKjhVc8N5k9aYrA6rv" type="image/svg+xml">
<img src="https://bunn.mypinata.cloud/ipfs/QmQ8i57dtp4fFDpn1Am3T9j4mUVoGKjhVc8N5k9aYrA6rv"></img></object>

               </div>
              <p className="paragraph-24">Mint </p>
            </a>
          </li>
        </ul>
      </div>
      <div className="div-block-253">
        <div className="div-block-254">
          <div><img src="images/bunnypunk_token4x_1bunnypunk_token4x.png" loading="lazy" srcset="images/bunnypunk_token4x_1-p-500.png 500w, images/bunnypunk_token4x_1-p-3200.png 3200w, images/bunnypunk_token4x_1bunnypunk_token4x.png 7815w" sizes="(max-width: 767px) 100vw, (max-width: 1279px) 31px, (max-width: 1919px) 2vw, 31px" alt="" className="image-122 logobotomm"></img></div>
          <div>
            <p className="paragraph-24 account-address"> Welcome!</p>
          </div>
          <div>
            <div className="html-embed-15 w-embed">
              
              </div>
          </div>
        </div>
      </div>
    </div>
    <div className="p-section-main wf-section">
      <div className="div-block-255">
        <div className="div-block-261">
          <div className="div-block-262">
            <h1 className="heading-40 dashboard-heading-2">BP OG Staking</h1>
          </div>
        </div>
      </div>
      <div className="s2isikksksk">
        <div className="se2sdas2222">
        <div className="opsd02sls">
        <div>
          <h1 className="heading-40">Bunny Punk OG Staking <span className="text-span-22">network: Elastos</span><span className="elastos-network"><strong></strong></span></h1>
        </div>
        <div className="div-block-278">
          <a href="#" className="button-20 go-staking nomnrgsn2 btn-2-32 styled-btn soundbtn w-button centered-22-2-2" onClick={connectWallet}>Hop in this adventure</a>
        </div>
      </div>
        </div>
      </div>

    </div>
  </div>
  <script src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=628e4d0f5a54d970034419e9" type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous"></script>
  <script src="js/webflow.js" type="text/javascript"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/placeholders/3.0.2/placeholders.min.js"></script>

</div>
      

    </section>
  )
}

export default Main